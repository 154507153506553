<template>
  <div v-if="loading" class="loading-page">
    <div class="loading" />
  </div>
</template>
<script>
export default {
  name: 'LoadingBar',
  props: {
    loading: {
      type: Boolean,
      require: true,
      default: () => false
    }
  }
}
</script>
<style lang="scss" scoped>
.loading-page {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.loading {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 6px solid $primary-border-color;
  border-radius: 50%;
  border-top-color: $brand-primary-color;
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
